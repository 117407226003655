import React from 'react';
import {SearchImg, SearchInput, SearchWrapper} from './styles';

export const Search: React.FC<{ onSearch: (query: string) => void }> = ({onSearch}) => {
    function onChanges(e: React.ChangeEvent<HTMLInputElement>) {
        onSearch(e.target.value);
    }

    return (
        <SearchWrapper>
            <SearchInput
                type="text"
                onChange={onChanges}
                placeholder='search'
            />
            <SearchImg
                src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/icons/icon-search.png`}
                alt='Search'/>
        </SearchWrapper>
    );
};
