import {IMember} from "../../api/model/IMember";

export default class MemberUtils {

    static filterByQuery(members: IMember[], query: string) {
        const lowerCaseQuery = query.toLowerCase().trim()
        const queryList = lowerCaseQuery.split(" ").map(query => query.trim())
        return members.filter(member => (matchQuery(member, queryList)))

        function matchQuery(member: IMember, queryList: string[]) {
            if (queryList.length === 2) {
                return twoNamesCriteria(member, queryList[0], queryList[1])
            } else {
                return queryList.some(query => anyNameCriteria(member, query))
            }
        }

        function anyNameCriteria(member: IMember, query: string) {
            return member.name.toLowerCase().includes(query)
                || member.surname.toLowerCase().includes(query)
                || member.maidenName?.toLowerCase().includes(query)
        }

        function twoNamesCriteria(member: IMember, queryFirst: string, querySecond: string) {
            const name = member.name?.toLowerCase()
            const surname = member.surname?.toLowerCase()
            const maidenName = member.maidenName?.toLowerCase()

            return name.includes(queryFirst) && surname.includes(querySecond)
                || name.includes(querySecond) && surname.includes(queryFirst)
                || name.includes(queryFirst) && maidenName?.includes(querySecond)
                || name.includes(querySecond) && maidenName?.includes(queryFirst)
        }
    }
}