import React, {ReactNode} from "react";
import {Content, Header, SectionBox} from "./styles";
import {Search} from "../Search";
import {Edit} from "../Edit";

export const Section: React.FC<{
    header: string,
    body: ReactNode,
    searchable?: boolean,
    editable?: boolean,
    onSearch?: (query: string) => void,
    onEdit?: () => void,
    onSave?: () => void,
    onCancel?: () => void
}> = ({
          header,
          body,
          searchable = false,
          editable = false,
          onSearch = () => {
          },
          onEdit = () => {
          },
          onSave = () => {
          },
          onCancel = () => {
          }
      }) => {
    return (
        <>
            <SectionBox>
                <Header>
                    {header}
                    {
                        searchable
                            ? <Search onSearch={onSearch}></Search>
                            : null
                    }
                    {
                        editable
                            ? <Edit onEdit={onEdit} onSave={onSave} onCancel={onCancel}></Edit>
                            : null
                    }
                </Header>
                <Content>
                    <>
                        {body}
                    </>
                </Content>
            </SectionBox>
        </>
    );
}