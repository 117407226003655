import React, {useEffect, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import {Card, CardPhoto, CardTitle, Info, Name} from "./styles";
import {MemberApi} from "../../api/MemberApi";
import {IMember} from "../../api/model/IMember";
import {Section} from "../Section";
import MemberUtils from "../../util/helper/MemberUtils";

export interface ICardDetail {
    isLoading: boolean
    photo: string
    name: string
    birthday: string
    age: string
    isToday: boolean,
    onClick: () => void;
}

export const Members = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [members, setMembers] = useState<Array<IMember>>([]);
    const [allMembers, setAllMembers] = useState<Array<IMember>>([]);

    const memberApi = new MemberApi()
    let navigate = useNavigate()

    useEffect(() => (findAndSetAllMembers()), [])

    function findAndSetAllMembers() {
        memberApi.getMembers()
            .then((data) => {
                setAllMembers(data);
                setMembers(data);
                setIsLoading(data.length === 0)
            });
    }

    function filterMembersByQuery(query: string) {
        let filteredMembers = MemberUtils.filterByQuery(allMembers, query)
        setMembers(filteredMembers);
        setIsLoading(filteredMembers.length === 0)
    }

    function onSearch(query: string) {
        if (query === "" || query === undefined) {
            findAndSetAllMembers()
        } else {
            filterMembersByQuery(query)
        }
    }

    return (
        <>
            <Section
                header={"Members"}
                searchable={true}
                onSearch={onSearch}
                body={
                    <ScrollMenu Header>
                        {
                            isLoading
                                ? showLoadingCard()
                                : showCards(members, navigate)
                        }
                    </ScrollMenu>
                }/>
        </>
    )
}

const CardWrapper: React.FC<ICardDetail> = ({
                                                onClick,
                                                isLoading,
                                                photo,
                                                name,
                                                birthday,
                                                age,
                                                isToday
                                            }) => {
    return (
        <Card onClick={onClick} confetti={isToday}>
            <CardPhoto photo={photo} isLoading={isLoading}></CardPhoto>
            <CardTitle>
                <Name>{name}</Name>
                <Info>{birthday}</Info>
                <Info>{age}</Info>
            </CardTitle>
        </Card>
    );
}

function showLoadingCard() {
    return <CardWrapper isLoading={true}
                        photo={""}
                        name={""}
                        birthday={""} age={""}
                        isToday={false}
                        onClick={() => {
                        }}/>
}

function showCards(members: Array<IMember>, navigate: NavigateFunction) {
    return members.map(member => {
        let thumbnail = member.thumbnail ? `/files/${member.thumbnail}` : process.env.PUBLIC_URL + 'images/no-photo.png'
        let fullName = `${member.name} ${member.surname}`;
        let [birthday, age] = dateToDisplay(member.birthday, member.deathDate)
        return <CardWrapper isLoading={false}
                            photo={thumbnail}
                            name={fullName}
                            birthday={birthday}
                            age={age}
                            isToday={false}
                            onClick={() => {
                                navigate(`/members/${member.id}/profile`)
                            }}/>
    })
}

function dateToDisplay(birthdayInSeconds: number | undefined, deathDateInSeconds: number | undefined) {
    let dateToShow = "No date provided"
    let ageToShow = ""

    if (birthdayInSeconds) {
        const fromDate = new Date(birthdayInSeconds * 1000)
        const tillDate = deathDateInSeconds ? new Date(deathDateInSeconds * 1000) : new Date()
        const age = calculateYears(fromDate, tillDate)
        dateToShow = fromDate.toLocaleDateString(window.navigator.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        if (deathDateInSeconds && deathDateInSeconds > birthdayInSeconds) {
            dateToShow = dateToShow + " - " + tillDate.toLocaleDateString(window.navigator.language, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })
        }
        if (!deathDateInSeconds || deathDateInSeconds > birthdayInSeconds) {
            ageToShow = `Возраст ${age}`
        }
    }
    return [dateToShow, ageToShow];
}

function calculateYears(fromDate: Date, tillDate: Date) {
    let yearDifference = tillDate.getFullYear() - fromDate.getFullYear();
    let oneOrZero = (tillDate.getMonth() < fromDate.getMonth()) ||
    (tillDate.getMonth() === fromDate.getMonth() && tillDate.getDate() < fromDate.getDate()) ? 1 : 0;
    return yearDifference - oneOrZero;
}